/**
 * Prepares R filter from queryBuilder's return object
 * (c) 2020 UDA Consulting
 * @param  {Object}     query The query object
 */

const finalQuery = function (prop) {
  /**
   * If the path is a string, convert it to an array
   * @param  {object}    object queryBuilder's object
   * @return {Object}    qSkeleton for label and for R
  */
  // console.log(prop)

  /**
   * ********************************************
   *         STEP-1 Extract rule details
   *
   * ********************************************
  */

  const query = prop.data
  let finalqv = prop.skeleton.value
  let finalql = prop.skeleton.label
  // console.log(query)

  /**
   * ********************************************
   *          STEP-2 Prepare operands
   *
   * ********************************************
  */

  // Function for replace string in query value...
  const replaceQv = (placeholder, value) => {
    finalqv = finalqv.replace(placeholder, value)
  }

  // and in query label
  const replaceQl = (placeholder, value) => {
    finalql = finalql.replace(placeholder, value)
  }

  const finalq = []
  for (let i = 0, len = query.length; i < len; i++) {
    const op = query[i].operator
    // console.log(typeof query[i].value !== 'string' & typeof query[i].value !== 'number')
    // console.log(query[i])
    let qv = ''
    let ql = ''

    if (typeof query[i].value !== 'string' & typeof query[i].value !== 'number') {
      // select-multiple case
      // grepl(" string ", varname) | grepl(" string ", varname) ...
      query[i].value.forEach(element => {
        qv = qv + 'grepl(" ' + element + ' ", ' + query[i].rule + ') | '
      })
      qv = qv.slice(0, -3) // delete last 3 chars e.g. ' | '
      // console.log(qv)
      ql = query[i].operand + ' is ' + query[i].value
    } else if (!('operator' in query[i]) | op === undefined) {
      // select-one case
      qv = query[i].rule + ' == "' + query[i].value + '"'
      ql = query[i].operand + ' is "' + query[i].value + '"'
    } else if (op === '>' | op === '>=' | op === '<' | op === '<=') {
      // varname [op] number
      qv = query[i].rule + ' ' + op + ' ' + query[i].value
      ql = query[i].operand + ' ' + op + ' ' + query[i].value
    } else {
      // all other cases
      // console.log(op)
      switch (op) {
        case '=':
          // varname == number
          qv = query[i].rule + ' == ' + query[i].value
          ql = query[i].operand + ' ' + op + ' ' + query[i].value
          break
        case '<>':
          // varname != number
          qv = query[i].rule + ' != ' + query[i].value
          ql = query[i].operand + ' ' + op + ' ' + query[i].value
          break
        case 'equals':
          // varname == "string"
          qv = query[i].rule + ' == "' + query[i].value + '"'
          ql = query[i].operand + ' ' + op + ' "' + query[i].value + '"'
          break
        case 'does not equal':
          // varname != "string"
          qv = query[i].rule + ' != "' + query[i].value + '"'
          ql = query[i].operand + ' ' + op + ' "' + query[i].value + '"'
          break
        case 'contains':
          // grepl("string", varname)
          qv = 'grepl("' + query[i].value + '", ' + query[i].rule + ')'
          ql = query[i].operand + ' ' + op + ' "' + query[i].value + '"'
          break
        case 'does not contain':
          // !grepl("string", varname)
          qv = '!grepl("' + query[i].value + '", ' + query[i].rule + ')'
          ql = query[i].operand + ' ' + op + ' "' + query[i].value + '"'
          break
        case 'is empty':
          // is.na(varname)                  varname = ""
          qv = 'is.na(' + query[i].rule + ')'
          // qv = query[i].rule + ' == ""'
          ql = query[i].operand + ' ' + op
          break
        case 'is not empty':
          // !is.na(varname)                 varname != ""
          qv = '!is.na(' + query[i].rule + ')'
          // qv = query[i].rule + ' != ""'
          ql = query[i].operand + ' ' + op
          break
        case 'begins with':
          // grepl("^string", varname)
          qv = 'grepl("^' + query[i].value + '", ' + query[i].rule + ')'
          ql = query[i].operand + ' ' + op + ' "' + query[i].value + '"'
          break
        case 'ends with':
          // grepl("string$", varname)
          qv = 'grepl("' + query[i].value + '$", ' + query[i].rule + ')'
          ql = query[i].operand + ' ' + op + ' "' + query[i].value + '"'
          break
        case 'after':
          // varname after "date"
          // qv = query[i].rule + ' > "' + query[i].value + '"'
          // ql = query[i].operand + ' ' + op + ' "' + query[i].value + '"'
          qv = `${query[i].rule} > "${query[i].value}"`
          ql = `${query[i].operand} ${op} "${query[i].value}"`
          break
        case 'before':
          // varname before "date"
          // qv = query[i].rule + ' < "' + query[i].value + '"'
          // ql = query[i].operand + ' ' + op + ' "' + query[i].value + '"'
          qv = `${query[i].rule} < "${query[i].value}"`
          ql = `${query[i].operand} ${op} "${query[i].value}"`
          break
          default:
          qv = 'defVal'
          ql = ' Default Label'
          break
      }
    }
    const qitems = { v: qv, t: ql, f: query[i].value }

    finalq.push(qitems)
  }
  // console.log(finalq)

  for (let i = 0, len = finalq.length; i < len; i++) {
    const placeholder = 'v' + i
    replaceQv(placeholder, finalq[i].v)
    replaceQl(placeholder, finalq[i].t)
    replaceQl(placeholder, finalq[i].t)
  }

  const finalquery = { query: finalqv, label: finalql }

  return finalquery

  /**
   * ********************************************
   *              R filter rules
   *
   * ********************************************
  */

  /* string filters
    equals:               varname = "string"
    does not equal:       varname != "string"
    contains:             grepl("string", varname)
    does not contain:     !grepl("string", varname)
    is empty:             is.na(varname)                  varname = ""
    is not empty:         !is.na(varname)                 varname != ""
    begins with:          grepl("^string", varname)
    ends with:            grepl("string$", varname)
  */

  /* multiple select filters
    String should start and end with space
    grepl(" string ", varname) | grepl(" string ", varname)
  */

  /* number filters
    =       varname == number
    <>      varname != number
    >=       varname >= number
    <=       varname <= number
  */

  /* date filters
    =       varname == date
    <>      varname != date
    >       varname > date
    <       varname < date
  */
}

module.exports = finalQuery
