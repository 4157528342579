<template>
  <!-- eslint-disable vue/no-v-html -->
  <v-card
    class="my-2 pt-5 vqb-rule card"
    outlined
  >
    <!-- <div class="field-label is-normal">
      <label class="label mr-5">{{ rule.label }}</label>
    </div> -->
    <v-row>
      <v-card
        class="ml-2 mr-5 my-3"
        color="grey lighten-4"
        flat
      >
        {{ rule.label }}
      </v-card>
      <!-- List of operands (optional) -->
      <v-select
        v-if="typeof rule.operands !== 'undefined'"
        v-model="query.operand"
        class="mx-2"
        :items="rule.operands"
      />

      <!-- List of operators for Date (e.g. after, before, equals, does not equal) -->
      <v-col
        cols="auto"
        class="pa-0"
      >
        <v-select
          v-if="rule.inputType === 'date'"
          id="my-select"
          v-model="query.operator"
          :items="rule.operators"
          class="my-select mr-2"
          hide-details
          outlined
          dense
        />
      </v-col>

      <!-- List of operators (e.g. =, !=, >, <) -->
      <v-col
        cols="auto"
        class="pa-0"
      >
        <v-select
          v-if="typeof rule.operators !== 'undefined' && rule.operators.length > 1 && rule.inputType !== 'date'"
          id="my-select"
          v-model="query.operator"
          :items="rule.operators"
          class="my-select"
          hide-details
          outlined
          dense
        />
      </v-col>

      <!-- Basic text input -->
      <v-text-field
        v-if="rule.inputType === 'text'"
        v-model="query.value"
        class="mx-4"
        hide-details
        outlined
        dense
        :placeholder="labels.textInputPlaceholder"
      />

      <!-- Basic number input -->
      <v-text-field
        v-if="rule.inputType === 'number'"
        v-model="query.value"
        class="mx-4"
        :placeholder="labels.textInputPlaceholder"
        type="number"
        hide-details
        outlined
        dense
      />

      <!-- Datepicker -->
      <input
        v-if="rule.inputType === 'date'"
        v-model="query.value"
        class="form-control"
        type="date"
      >
      <!-- <v-menu
        v-if="rule.inputType === 'date'"
        v-model="query.value"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="date"
            label="Picker without buttons"
            prepend-icon="mdi-calendar"
            readonly
            hide-details
            v-bind="attrs"
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="date"
          @input="menu2 = false"
        />
      </v-menu> -->

      <!-- Custom component input -->
      <div
        v-if="isCustomComponent"
        class="vqb-custom-component-wrap mr-2"
      >
        <component
          :is="rule.component"
          :value="query.value"
          @input="updateQuery"
        />
      </div>

      <!-- Checkbox input -->
      <v-select
        v-if="rule.inputType === 'checkbox'"
        id="my-select"
        v-model="query.value"
        :items="rule.choices"
        item-text="label"
        item-value="value"
        class="my-select mr-2"
        chips
        clearable
        hide-details
        outlined
        multiple
        dense
      >
        <template v-slot:selection="{ item, index }">
          <v-chip v-if="index < 2">
            <span>{{ item.label }}</span>
          </v-chip>
          <span
            v-if="index === 2"
            class="grey--text caption"
          >
            (+{{ query.value.length - 2 }} others)
          </span>
        </template>
      </v-select>

      <!-- <template v-if="rule.inputType === 'checkbox'">
        <div class="mr-2">
          <div class="control">
            <div
              v-for="choice in rule.choices"
              :key="choice.value"
              class="checkbox mr-2"
            >
              <input
                :id="
                  'depth' +
                    depth +
                    '-' +
                    rule.id +
                    '-' +
                    index +
                    '-' +
                    choice.value
                "
                v-model="query.value"
                type="checkbox"
                :value="choice.value"
              >
              <label
                :for="
                  'depth' +
                    depth +
                    '-' +
                    rule.id +
                    '-' +
                    index +
                    '-' +
                    choice.value
                "
              >
                {{ choice.label }}
              </label>
            </div>
          </div>
        </div>
      </template> -->

      <!-- Radio input -->
      <template v-if="rule.inputType === 'radio'">
        <div class="mr-2">
          <div class="control">
            <div
              v-for="choice in rule.choices"
              :key="choice.value"
              class="radio mr-2"
            >
              <input
                :id="
                  'depth' +
                    depth +
                    '-' +
                    rule.id +
                    '-' +
                    index +
                    '-' +
                    choice.value
                "
                v-model="query.value"
                :name="'depth' + depth + '-' + rule.id + '-' + index"
                type="radio"
                :value="choice.value"
              >
              <label
                :for="
                  'depth' +
                    depth +
                    '-' +
                    rule.id +
                    '-' +
                    index +
                    '-' +
                    choice.value
                "
              >
                {{ choice.label }}
              </label>
            </div>
          </div>
        </div>
      </template>

      <!-- Select without groups -->
      <v-col
        cols="auto"
        class="pa-0"
      >
        <v-select
          v-if="rule.inputType === 'select' && !hasOptionGroups"
          id="my-select"
          v-model="query.value"
          :items="objtoarray(selectOptions)"
          item-text="label"
          item-value="value"
          class="my-select"
          hide-details
          outlined
          dense
        />
      </v-col>

      <!-- Select with groups -->
      <div
        v-if="rule.inputType === 'select' && hasOptionGroups"
        class="mr-2"
      >
        <div class="control">
          <div class="select">
            <select
              v-model="query.value"
              :multiple="rule.type === 'multi-select'"
            >
              <optgroup
                v-for="(option, option_key) in selectOptions"
                :key="option_key"
                :label="option_key"
              >
                <option
                  v-for="sub_option in option"
                  :key="sub_option.value"
                  :value="sub_option.value"
                >
                  {{ sub_option.label }}
                </option>
              </optgroup>
            </select>
          </div>
        </div>
      </div>

      <v-spacer />

      <!-- Remove rule button -->
      <v-btn
        class="delete is-medium ml-auto"
        color="error"
        icon
        dark
        @click="remove"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </v-row>
  </v-card>
</template>

<script>
  import QueryBuilderRule from 'vue-query-builder/dist/rule/QueryBuilderRule.umd.js'
  export default {
    extends: QueryBuilderRule,

    data: () => ({
      dateoperators: [
        { label: 'Before', value: '>' },
        { label: 'After', value: '<' },
        { label: 'Equals', value: '=' },
        { label: 'Does not equal', value: '!=' },
      ],
    }),

    methods: {
      objtoarray (obj) {
        return Object.keys(obj).map((key) => {
          return obj[key]
        })
      },
    },
  }
</script>

<style>
  input#my-select {
    position: absolute;
    display: inline;
    width: 0;
    height: 0;
    padding: 0;
  }
</style>
