<template>
  <!-- eslint-disable vue/no-v-html -->
  <v-container
    class="vqb-group card pa-2 mt-2"
    :class="'depth-' + depth.toString()"
    fluid
  >
    <div class="vqb-group-heading card-header">
      <!-- <div class="card-header-title">
      <div class="match-type-container field is-horizontal"> -->
      <v-toolbar
        color="primary"
        dark
        flat
        dense
      >
        <v-toolbar-title>{{ labels.matchType }} </v-toolbar-title>

        <v-col
          cols="auto"
          class="pa-0"
        >
          <v-select
            id="my-select"
            v-model="query.logicalOperator"
            :items="labels.matchTypes"
            item-text="label"
            item-value="id"
            class="my-select ml-2"
            hide-details
            outlined
            dense
          />
        </v-col>

        <v-spacer />

        <!-- Delete group button -->
        <v-btn
          v-if="depth > 1"
          :class="`mr-${3 - depth}`"
          icon
          dark
          @click="remove"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-toolbar>
      <!-- </div>
      </div> -->
    </div>

    <div class="vqb-group-body card-content">
      <div class="rule-actions">
        <div class="control">
          <div
            class="select"
            :class="'ml-' + depth.toString()"
          >
            <v-col
              cols="12"
            >
              <v-row
                class="select mt-1"
              >
                <v-select
                  v-model="selectedRule"
                  outlined
                  dense
                  class="ml-1 mr-4"
                  :items="rules"
                  item-text="label"
                  item-value="id"
                  hide-details
                  return-object
                />
                <v-btn
                  color="primary"
                  text
                  @click="addRule"
                >
                  {{ labels.addRule }}
                </v-btn>
                <v-btn
                  v-if="depth < maxDepth"
                  text
                  color="primary"
                  @click="addGroup"
                >
                  {{ labels.addGroup }}
                </v-btn>
              </v-row>
            </v-col>
          </div>
        </div>
      </div>

      <query-builder-children
        v-bind="$props"
        :class="`ml-${depth}`"
      />
    </div>
  </v-container>
</template>

<script>
  import QueryBuilderGroup from 'vue-query-builder/dist/group/QueryBuilderGroup.umd.js'
  import QueryBuilderRule from './VuetifyRule.vue'

  export default {
    name: 'QueryBuilderGroup',
    components: {
      // eslint-disable-next-line vue/no-unused-components
      QueryBuilderRule: QueryBuilderRule,
    },
    extends: QueryBuilderGroup,

    data: () => ({
    }),

    methods: {
      addRuletemp () {
        // console.log(this.selectedRule.type)
        this.addRule()
      },
    },
  }
</script>

<style>
  .vue-query-builder .vqb-group {
    border: 1px solid grey;
    border-radius: 5px;
  }
  .ml-auto {
    margin-left: auto;
  }
  .vue-query-builder .vqb-group .rule-actions {
    margin-bottom: 0px;
  }
  .vue-query-builder .vqb-rule {
    margin-top: 15px;
    margin-bottom: 15px;
    background-color: #f5f5f5;
    border-color: #ddd;
    padding: 15px;
  }
  .vue-query-builder .vqb-group.depth-1 .vqb-rule,
  .vue-query-builder .vqb-group.depth-2 {
    border-left: 3px solid #8bc34a;
  }
  .vue-query-builder .vqb-group.depth-2 .vqb-rule,
  .vue-query-builder .vqb-group.depth-3 {
    border-left: 3px solid #00bcd4;
  }
  .vue-query-builder .vqb-group.depth-3 .vqb-rule,
  .vue-query-builder .vqb-group.depth-4 {
    border-left: 3px solid #ff5722;
  }
  .vue-query-builder .close {
    opacity: 1;
    color: rgb(150, 150, 150);
  }
  @media (min-width: 768px) {
    .vue-query-builder .vqb-rule.form-inline .form-group {
      display: block;
    }
  }
</style>
