<template>
  <v-container
    id="quality-control"
    fluid
    tag="section"
  >
    <v-toolbar
      style="z-index: 1"
      color="elevation-0"
      dense
    >
      <v-toolbar-title class="text-h2 grey--text text--darken-0 pr-8">
        {{ $store.state.main.af.title }} - {{ $t('process.qcontrol') }}
      </v-toolbar-title>
      <v-spacer />

      <!-- Include Datasets for download, open and close card -->
      <v-btn
        v-if="myRole() === 'manager'"
        color="warning"
        fab
        dark
        @click="helptoolbar = !helptoolbar"
      >
        <v-icon>mdi-information-variant</v-icon>
      </v-btn>
    </v-toolbar>

    <v-row class="pt-4">
      <v-col
        cols="12"
      >
        <v-card
          class="mt-0"
          outlined
        >
          <v-card-text>
            <base-material-tabs
              v-model="selectedTab"
              color="warning"
              @change="tabChange"
            >
              <v-tab
                v-for="(tab) in tabs"
                :key="tab.name"
              >
                {{ tab.label }}
              </v-tab>

              <!-- Query List -->
              <v-tab-item>
                <v-card
                  flat
                  class="my-0"
                >
                  <v-toolbar flat>
                    <v-switch
                      v-model="singleExpand"
                      label="Single expand"
                      class="mt-6"
                    />
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      class="ml-auto"
                      label="Search"
                      hide-details
                      single-line
                      style="max-width: 250px;"
                    />
                  </v-toolbar>

                  <v-divider class="mt-3" />
                  <v-card-text>
                    <v-data-table
                      :headers="headers"
                      :items="queryAll"
                      :search.sync="search"
                      :sort-by="['qTitle']"
                      :sort-desc="[false, true]"
                      :single-expand="singleExpand"
                      :expanded.sync="expanded"
                      :loading="loading"
                      item-key="qTime[0]"
                      show-expand
                      multi-sort
                    >
                      <!-- Action buttons -->
                      <template v-slot:[`item.actions`]="{ item }">
                        <!-- Edit item -->
                        <v-tooltip bottom>
                          <template #activator="{ on }">
                            <v-icon
                              class="mr-3"
                              v-on="on"
                              @click="editItem(item)"
                            >
                              mdi-pencil
                            </v-icon>
                          </template>
                          <span>Edit query</span>
                        </v-tooltip>
                        <!-- Download item -->
                        <v-tooltip bottom>
                          <template #activator="{ on }">
                            <v-icon
                              class="mr-3"
                              v-on="on"
                              @click="downloadItem(item)"
                            >
                              mdi-download
                            </v-icon>
                          </template>
                          <span>{{ $t('process.download-resul') }}</span>
                        </v-tooltip>
                        <!-- Exclude Item -->
                        <!-- Delete Item -->
                        <v-tooltip bottom>
                          <template #activator="{ on }">
                            <v-icon
                              color="primary"
                              class="mr-3"
                              v-on="on"
                              @click="deleteItem(item)"
                            >
                              mdi-delete
                            </v-icon>
                          </template>
                          <span>{{ $t('process.delete-query') }}</span>
                        </v-tooltip>
                        <!-- To Exclude -->
                        <template v-if="!canbeexcluded(item)">
                          <v-tooltip
                            v-if="item.qExclude[0]"
                            bottom
                          >
                            <template #activator="{ on }">
                              <v-icon
                                color="primary"
                                v-on="on"
                                @click="excludeItem(item)"
                              >
                                mdi-filter
                              </v-icon>
                            </template>
                            <span>{{ $t('process.result-excluded') }}</span>
                          </v-tooltip>
                          <v-tooltip
                            v-else
                            bottom
                          >
                            <template #activator="{ on }">
                              <v-icon
                                v-on="on"
                                @click="excludeItem(item)"
                              >
                                mdi-filter-outline
                              </v-icon>
                            </template>
                            <span>{{ $t('process.exclude-result') }}</span>
                          </v-tooltip>
                        </template>
                      </template>
                      <!-- No data -Add button -->
                      <template v-slot:no-data>
                        <v-btn
                          color="primary"
                          @click="selectedTab = 1"
                        >
                          {{ $t('process.add-query') }}
                        </v-btn>
                      </template>
                      <!-- Query Details section -->
                      <template v-slot:expanded-item="{ item }">
                        <td :colspan="headers.length">
                          <div> {{ item.qLabel[0] }}</div>
                          {{ item.qValue[0] }}
                        </td>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <!-- Query Builder -->
              <v-tab-item>
                <v-card
                  flat
                  class="mb-0"
                >
                  <validation-observer v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(saveQuery)">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Dataset"
                        rules="required"
                      >
                        <v-select
                          v-model="selectedTable"
                          :error-messages="errors"
                          :items="$store.state.ocpu.tableListRules"
                          outlined
                          dense
                          hide-details
                          class="mb-4"
                          item-text="label[0]"
                          item-value="table[0]"
                          prepend-inner-icon="mdi-table"
                          label="Select a dataset"
                          return-object
                          @change="dataSetSelected"
                        />
                      </validation-provider>
                      <v-card
                        flat
                        class="pa-0 ma-0"
                      >
                        <!-- Vue Query Builder -->
                        <vue-query-builder
                          v-model="query"
                          :rules="rules"
                        >
                          <template v-slot:default="slotProps">
                            <query-builder-group
                              v-bind="slotProps"
                              :query.sync="query"
                            />
                          </template>
                        </vue-query-builder>

                        <v-card-actions class="px-0">
                          <validation-provider
                            v-slot="{ errors }"
                            name="Query Title"
                            rules="required"
                          >
                            <v-text-field
                              v-model="qTitle"
                              :error-messages="errors"
                              class="mt-5"
                              color="primary"
                              label="Query Title*"
                              outlined
                              dense
                              clearable
                            />
                          </validation-provider>

                          <v-spacer />
                          <v-btn
                            default
                            color="warning"
                            @click="initNewQuery()"
                          >
                            {{ $t('process.clear-all') }}
                          </v-btn>
                          <v-btn
                            default
                            color="success"
                            type="submit"
                          >
                            {{ $t('process.save-filter') }}
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </form>
                  </validation-observer>
                </v-card>
              </v-tab-item>
            </base-material-tabs>
          </v-card-text>
          <!-- <v-card-text>
            <pre>{{ JSON.stringify(query, null, 2) }}</pre>
          </v-card-text> -->
        </v-card>
      </v-col>
    </v-row>

    <!-- Delete filter - dialog box -->
    <v-row justify="center">
      <v-dialog
        v-model="dialogDeleteQuery"
        persistent
        max-width="450"
      >
        <v-card>
          <v-card-title class="headline font-weight-regular primary white--text py-2">
            Heads up!
          </v-card-title>
          <v-card-text>
            This Query will be deleted immediately. You can add more using "Builder" section.
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="grey darken-1"
              text
              @click="dialogDeleteQuery = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="warning"
              text
              @click="removeFromQuery"
            >
              Agree
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
  // import Vue from 'vue'
  import axios from 'axios'
  import VueQueryBuilder from 'vue-query-builder'
  import QueryBuilderGroup from './components/VuetifyGroup.vue'
  import queryBuilder from './components/queryBuilder'
  import filterBuilder from './components/filterBuilder'
  import userAccess from '@/mixins/user-access'

  // Vue.use(VueTailwind)

  export default {
    name: 'LogicBuilder',

    components: {
      VueQueryBuilder,
      QueryBuilderGroup,
    },

    mixins: [
      userAccess,
    ],

    data: () => ({
      tab: 0,
      tabs: [
        { label: 'Existing Queries', name: 'queries' },
        { label: 'Builder', name: 'builder' },
      ],
      formid: '',
      selectedTab: 0,

      selectedTable: '',
      // rules: [
      //   {
      //     type: 'text',
      //     id: 'vegetable',
      //     label: 'Vegetable',
      //   },
      //   {
      //     type: 'numeric',
      //     id: 'count',
      //     label: 'Count',
      //   },
      //   {
      //     type: 'select',
      //     id: 'seelcts',
      //     label: 'Fruit-select',
      //     choices: [
      //       { label: 'Applestrg dyhj fdgty', value: 'apple' },
      //       { label: 'Banana sg srtg', value: 'banana' },
      //       { label: 'Apple  gsdfgrt st2', value: 'apple2' },
      //       { label: 'Banana3freqr gfsg rt', value: 'banana3' },
      //     ],
      //   },
      //   {
      //     type: 'checkbox',
      //     id: 'check',
      //     label: 'Checkb',
      //     choices: [
      //       { label: 'Apple', value: 'apple' },
      //       { label: 'Bananaasdf fqaer ', value: 'banana' },
      //       { label: 'Apple2asdf asdf', value: 'apple2' },
      //       { label: 'Banana3 asdf asdf a', value: 'banana3' },
      //     ],
      //   },
      //   {
      //     type: 'radio',
      //     id: 'fruit',
      //     label: 'Fruit',
      //     choices: [
      //       { label: 'Apple', value: 'apple' },
      //       { label: 'Bananasdf asdf asdf a', value: 'banana' },
      //       { label: 'Appasdf asdf asdf ale2', value: 'apple2' },
      //       { label: 'Banansdf asdf asdf a3', value: 'banana3' },
      //     ],
      //   },
      //   {
      //     type: 'date',
      //     id: 'date-type',
      //     label: 'Date',
      //     inputType: 'date',
      //     // operators: ['>', '<', '='],
      //     operators: [
      //       { label: 'Before', value: '>' },
      //       { label: 'After', value: '<' },
      //       { label: 'Equals', value: '=' },
      //       { label: 'Does not equal', value: '!=' },
      //     ],
      //   },
      // ],
      rules: [],
      query: { logicalOperator: 'all', children: [] },
      qTitle: '',
      queryAll: [{ count: 0, qExclude: false, qFull: '', qLabel: '', qTime: 0, qTitle: '', qUser: '', qValue: '', tblLabel: '', tblName: '' }],

      // filterRenewed: false,
      loading: false,

      headers: [
        { text: '', value: 'data-table-expand' },
        { text: 'Query', value: 'qTitle' },
        { text: 'Dataset', value: 'tblLabel' },
        { text: 'User', value: 'qUser' },
        { text: 'Total', value: 'count' },
        { sortable: false, text: 'Actions', value: 'actions' },
      ],
      search: undefined,
      expanded: [],
      singleExpand: true,

      dialogDeleteQuery: false,
      selectedQuery: null,
      selectedQueryIndex: -1,

      // operandList: [
      //   { key: '=', value: '=' },
      //   { key: 'equals', value: '=' },
      //   { key: 'does not equal', value: '!=' },
      // ],

      // filter parts
      skeleton: {},
    }),

    computed: {

      orgTitle () {
        return this.$store.state.bend.organization.title
      },

      // variables () {
      //   // console.log(this.rules)
      //   return this.rules
      // },

    },

    mounted () {
      this.formid = this.$store.state.main.afid
      this.getQuery()

      // this.$store
      //   .dispatch({
      //     type: 'ocpu/getQuery',
      //     formid: this.formid,
      //   })
      //   .then(response => {
      //     this.queryAll = response.data
      //     //console.log(this.queryAll)
      //   })
    },

    methods: {

      // Get filters to ocpu
      getQuery () {
        this.loading = true
        const ocpuPack = this.$store.state.ocpu.ocpuPack
        return new Promise((resolve, reject) => {
          this.$httpOcpu
            .post(`/${ocpuPack}/R/ocgetqueries/json`, {
              orgid: this.$store.state.main.orgid,
              prjid: this.$store.state.main.ap.prjid,
              formid: this.formid,
            })
            .then(response => {
              this.queryAll = response.data
              this.loading = false
              // console.log(this.queryAll)
            })
            .catch(error => {
              // console.log(error)
              reject(error)
            })
        })
      },

      // Clear all fields in Query Builder. Ready for new Query
      initNewQuery () {
        this.selectedTable = ''
        this.query = { logicalOperator: 'all', children: [] }
        // this.rules = []
        this.qTitle = ''
        this.selectedQuery = null
        this.selectedQueryIndex = -1
      },

      dataSetSelected (dataSet) {
        // Clear all fields in Query Builder
        this.initNewQuery()

        // console.log(dataSet)
        this.selectedTable = dataSet

        // find table object by filtering selected value
        // const myData = this.$store.state.ocpu.tableListRules.filter(function (item) {
        //   return item.table.includes(dataSet.table)
        // })

        // Some data has undefined type : change them into 'text'
        dataSet.column.forEach(function (part) {
          if (part.type === undefined) {
            part.type = 'text'
          }
        })
        this.rules = dataSet.column
        // console.log(this.rules)
      },

      // Clear all if Builder tab is openned
      tabChange () {
        if (this.selectedTab === 1) {
          // Clear all fields in Query Builder
          this.initNewQuery()
        }
      },

      async saveQuery () {
        // console.log('myquery')
        // Convert a Query javaScript object to a JSON string for further process
        const myquery = JSON.stringify(this.query)
        // console.log(myquery)

        // Check if there is empty group, we should not continue in this case
        const emptygroup = /","children":[[]]}}/g
        const queryError = myquery.search(emptygroup) >= 0 ? 'empty_group' : ''

        // Traversing function to exrtact rule details
        // https://learn.co/lessons/js-looping-and-iteration-traversing-nested-objects-readme
        const ruleDetails = []
        function getRuleDetails (target) {
          if (typeof target === 'object') {
            for (const key in target) {
              if (key === 'rule') {
                ruleDetails.push(target)
              }
              getRuleDetails(target[key])
            }
          }
        }

        // Run traversing function to get rule details
        getRuleDetails(this.query)
        // console.log(ruleDetails.length)
        // console.log(ruleDetails)

        // Prepare skeleton of the query
        let skeleton = ''
        if (queryError.length > 0) {
          // console.log('Empty group is detected')
          // TODO popup ERROR
        } else if (ruleDetails.length === 1) {
          // if only one variable this is the skeleton
          skeleton = { value: '( v0 )', label: '( v0 )' }
        } else {
          // get skeleton from queryBuilder()
          skeleton = queryBuilder(this.query)
        }

        if (skeleton.length !== 0) {
          // process R filter based on query
          // console.log(skeleton)

          const queryParts = filterBuilder({ data: ruleDetails, skeleton: skeleton })
          // const queryParts = filterBuilder(queryDrafts)
          // console.log(queryParts)

          const qTime = new Date().getTime()
          // console.log(qTime)

          // save query to server
          const queryArray = {
            qTime: String(qTime),
            qValue: queryParts.query,
            qLabel: queryParts.label,
            qFull: JSON.stringify(this.query),
            qTitle: this.qTitle,
            qUser: this.$store.state.auth.user.username,
            qExclude: false,
            tblName: this.selectedTable.table,
            tblLabel: this.selectedTable.label,
            count: [0],
          }

          // if Editing existing query first delete the old one
          if (this.selectedQueryIndex >= 0) {
            this.queryAll.splice(this.selectedQueryIndex, 1)
          }

          // clone this.queryAll and push new queryArray
          const queryToSend = this.queryAll.slice()
          queryToSend.push(queryArray)

          // prepare it to send
          const queries = JSON.stringify(queryToSend)

          // TODO Separate into methods with await
          // console.log(queries)
          this.$store
            .dispatch({
              type: 'ocpu/saveQuery',
              queries: queries,
              orgid: this.$store.state.main.orgid,
              prjid: this.$store.state.main.ap.prjid,
              formid: this.formid,
            })
            .then(response => {
              this.$store
                .dispatch({
                  type: 'ocpu/applyQueries',
                  orgid: this.$store.state.main.orgid,
                  prjid: this.$store.state.main.ap.prjid,
                  formid: this.formid,
                })
                .then(response => {
                  this.getQuery()
                })
              // console.log(response.data)
            })

          // Activate Query list tab
          this.selectedTab = 0
        }

        /*
        // traverse function. loop nested object
        function iter (o) {
          Object.keys(o).forEach(function (k) {
            if (o[k] !== null && typeof o[k] === 'object') {
              iter(o[k])
              return
            }
            o[k] = [o[k]]
            // console.log(o[k])
          })
        }
        const data = JSON.parse(JSON.stringify(this.query))
        iter(data)
        */
      },

      sendQuery () {
        // clone this.queryAll and push new queryArray
        const queryToSend = this.queryAll.slice()

        // prepare it to send
        const queries = JSON.stringify(queryToSend)

        // TODO Separate into methods with await
        // console.log(queries)
        this.$store
          .dispatch({
            type: 'ocpu/saveQuery',
            queries: queries,
            orgid: this.$store.state.main.orgid,
            prjid: this.$store.state.main.ap.prjid,
            formid: this.formid,
          })
          .then(response => {
            this.$store
              .dispatch({
                type: 'ocpu/applyQueries',
                orgid: this.$store.state.main.orgid,
                prjid: this.$store.state.main.ap.prjid,
                formid: this.formid,
              })
              .then(response => {
                this.getQuery()
              })
            // console.log(response.data)
          })
      },

      downloadItem (item) {
        // console.log(item.qTime[0])
        this.$store
          .dispatch({
            type: 'ocpu/downloadQuery',
            orgid: this.$store.state.main.orgid,
            prjid: this.$store.state.main.ap.prjid,
            formid: this.formid,
            fileid: item.qTime[0],
          })
          .then(response => {
            // console.log(response)
            const fileName = response.substring(response.lastIndexOf('/') + 1)
            axios({
              url: response,
              method: 'GET',
              responseType: 'blob',
            }).then(response => {
              var fileURL = window.URL.createObjectURL(new Blob([response.data]))
              var fileLink = document.createElement('a')
              fileLink.href = fileURL
              fileLink.setAttribute('download', fileName)
              document.body.appendChild(fileLink)
              fileLink.click()
            })
            // console.log(response)
          })
      },

      editItem (item) {
        // console.log(item)
        this.selectedQueryIndex = this.queryAll.indexOf(item)
        this.selectedQuery = Object.assign({}, item)

        this.query = { logicalOperator: 'all', children: [] }
        this.selectedTab = 1

        const myData = this.$store.state.ocpu.tableListRules.filter(function (list) {
          return list.table.includes(item.tblName[0])
        })
        this.selectedTable = myData[0]

        this.rules = myData[0].column
        // console.log(this.rules)
        this.query = JSON.parse(item.qFull)
        // console.log(this.query)
        this.qTitle = item.qTitle
      },

      canbeexcluded (item) {
        const filter = this.queryAll.filter(x => x.qTime[0] === item.qTime[0])
        return filter[0].tblName[0] !== this.formid
      },

      excludeItem (item) {
        // console.log(item)
        // Find index of this query
        const index = this.queryAll.findIndex(x => x.qTime[0] === item.qTime[0])
        this.queryAll[index].qExclude[0] = !this.queryAll[index].qExclude[0]
        this.sendQuery()
        this.getQuery()
      },

      deleteItem (item) {
        this.selectedQueryIndex = this.queryAll.indexOf(item)
        this.dialogDeleteQuery = true
      },

      removeFromQuery () {
        this.queryAll.splice(this.selectedQueryIndex, 1)
        const filters = JSON.stringify(this.queryAll)
        this.$store
          .dispatch({
            type: 'ocpu/saveQuery',
            queries: filters,
            orgid: this.$store.state.main.orgid,
            prjid: this.$store.state.main.ap.prjid,
            formid: this.formid,
          })
          .then(response => {
            // console.log(response)
          })
        this.selectedQuery = null
        this.selectedQueryIndex = -1
        this.dialogDeleteQuery = false
        // this.filterRenewed = true
      },

    },
  }
</script>

<style>
  tr.v-data-table__expanded__content {
      box-shadow: 0px 0px !important;
  }
</style>
