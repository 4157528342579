/**
 * Prepares Skeleton of the vue-query-builder's query for further use in filter Builder
 * (c) 2020 UDA Consulting
 * @param  {Object}     query The query object
 */

const saveQuery = function (query) {
  /**
   * If the path is a string, convert it to an array
   * @param  {object}    object vue-query-builder's query
   * @return {Object}    object qDetails, qSkeleton and qOerands
  */

  /**
   * ********************************************
   * STEP-1 Preparatory functions and array lists
   *
   * ********************************************
  */

  // Which query should be used?
  const myquery = JSON.stringify(query)

  // function to prepare object with positions of strings in sPartList
  function findAllPositions (searchStr, part, myquery, indices) {
    const l = searchStr.length
    let p = {}
    let np = part
    let startIndex = 0
    let index
    let more = null

    if (l === 0) {
      return []
    }
    let i = 0
    while ((index = myquery.indexOf(searchStr, startIndex)) > -1) {
      switch (part) {
        case ' v':
          np = part + i + ' '
          more = 'v'
          break
        case '( ':
          more = myquery.slice(l + index, 3 + l + index)
          break
        default:
          break
      }
      p = { part: np, pos: index, more: more }
      indices.push(p)
      startIndex = index + l
      i = i + 1
    }
    return indices
  }

  //
  const sPartList = [
    { part: '( ', str: '{"logicalOperator":"' },
    { part: ' v', str: 'query-builder-rule' },
    { part: ' )', str: '}}]' },
    { part: ' o ', str: '}},{' },
  ]

  const indices = []
  for (let i = 0; i < sPartList.length; i++) {
    findAllPositions(sPartList[i].str, sPartList[i].part, myquery, indices)
    //
  }
  indices.sort((a, b) => (a.pos > b.pos) ? 1 : ((b.pos > a.pos) ? -1 : 0))
  // console.log(indices)

  let mq = ''

  const operators = []
  indices.forEach(element => {
    let el = element.part
    if (el === '( ') operators.push(element.more)
    if (el === ' )') operators.splice(-1, 1)
    if (el === ' o ') el = ' ' + operators[operators.length - 1] + ' '
    mq = mq + el
  })
  // console.log(mq)

  let vSkeleton = mq.slice()
  // delete all double spaces
  while (vSkeleton.search('  ') >= 0) {
    vSkeleton = vSkeleton.split('  ').join(' ')
  }

  vSkeleton = vSkeleton.split('all').join('&')
  vSkeleton = vSkeleton.split('any').join('|')
  // console.log(vSkeleton)
  let lSkeleton = vSkeleton.split('&').join('AND')
  lSkeleton = lSkeleton.split('|').join('OR')
  const skeleton = { value: vSkeleton, label: lSkeleton }

  return skeleton
}

module.exports = saveQuery
